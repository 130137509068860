import React from 'react';
import { graphql, Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import uniqueId from 'uniqid';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Header from '../components/Header';
import Logo from '../assets/YAIR_LOGO.svg';

const ns = 'cv-page';

const CVPage = ({ location, data }) => {
  const {
    cv: {
      frontmatter: {
        columns,
      },
    },
  } = data;

  const rootClassnames = classNames({
    [`${ns}`]: true,
  });

  return (
    <Layout location={location} title="YAIR">
      <Seo title="CV" />
      <div className={rootClassnames}>
        <Header />
        <div className={`${ns}__content`}>
          <div className={`${ns}_columns`}>
            {columns.map((column) => (
              <div className={`${ns}__column`} key={uniqueId('yr')}>
                <h3 className={`${ns}__column-title`}>{column.title}</h3>
                <div className={`${ns}__column-container`}>
                  {column.accolades.map((accolade) => (
                    <div className={`${ns}__column-content`} key={uniqueId('yr')}>
                      <p>{accolade.year}</p>
                      <p>{accolade.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`${ns}__logo`}>
          <div className={`${ns}__logo-container`}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default CVPage;

export const cvPageQuery = graphql`
  query CVPage($id: String!) {
    cv: markdownRemark(id: { eq: $id }) {
      frontmatter {
        columns {
          title
          accolades {
            year
            description
          }
        }
      }
    }
  }
`;

CVPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};
